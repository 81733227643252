// https://github.com/blocknative/web3-onboard/tree/main/packages/injected/src/icons
export const getIconByName = {
  '1inch Wallet': async () => (await import('./icons/oneInch.js')).default,
  AToken: async () => (await import('./icons/atoken.js')).default,
  AlphaWallet: async () => (await import('./icons/alphawallet')).default,
  'Apex Wallet': async () => (await import('./icons/apexwallet.js')).default,
  'Bifrost Wallet': async () => (await import('./icons/bifrostwallet.js')).default,
  'Binance Smart Wallet': async () => (await import('./icons/binance.js')).default,
  BitKeep: async () => (await import('./icons/bitkeep.js')).default,
  Bitpie: async () => (await import('./icons/bitpie.js')).default,
  Bitski: async () => (await import('./icons/bitski.js')).default,
  BlockWallet: async () => (await import('./icons/blockwallet.js')).default,
  'Brave Wallet': async () => (await import('./icons/brave.js')).default,
  'Coinbase Wallet': async () => (await import('./icons/coinbase.js')).default,
  Core: async () => (await import('./icons/core.js')).default,
  "D'CENT": async () => (await import('./icons/dcent.js')).default,
  'DeFi Wallet': async () => (await import('./icons/defiwallet.js')).default,
  'Detected Wallet': async () => (await import('./icons/detected.js')).default,
  Enkrypt: async () => (await import('./icons/enkrypt.js')).default,
  Exodus: async () => (await import('./icons/exodus.js')).default,
  Frame: async () => (await import('./icons/frame.js')).default,
  Frontier: async () => (await import('./icons/frontier.js')).default,
  'GameStop Wallet': async () => (await import('./icons/gamestop.js')).default,
  'Huobi Wallet': async () => (await import('./icons/huobiwallet.js')).default,
  HyperPay: async () => (await import('./icons/hyperpay.js')).default,
  'Infinity Wallet': async () => (await import('./icons/infinitywallet.js')).default,
  Liquality: async () => (await import('./icons/liquality.js')).default,
  MathWallet: async () => (await import('./icons/mathwallet.js')).default,
  MeetOne: async () => (await import('./icons/meetone.js')).default,
  // 'MetaMask': async () => (await import('./icons/metamask.js')).default,
  MyKey: async () => (await import('./icons/mykey.js')).default,
  'OKX Wallet': async () => (await import('./icons/okxwallet.js')).default,
  OneKey: async () => (await import('./icons/onekey.js')).default,
  'Opera Wallet': async () => (await import('./icons/opera.js')).default,
  OwnBit: async () => (await import('./icons/ownbit.js')).default,
  Phantom: async () => (await import('./icons/phantom.js')).default,
  'Rabby Wallet': async () => (await import('./icons/rabby.js')).default,
  Rainbow: async () => (await import('./icons/rainbow.js')).default,
  SafePal: async () => (await import('./icons/safepal.js')).default,
  Safeheron: async () => (await import('./icons/safeheron.js')).default,
  Sequence: async () => (await import('./icons/sequence.js')).default,
  'Status Wallet': async () => (await import('./icons/status.js')).default,
  'TP Wallet': async () => (await import('./icons/tp.js')).default,
  Taho: async () => (await import('./icons/tallywallet.js')).default,
  Talisman: async () => (await import('./icons/talisman.js')).default,
  TokenPocket: async () => (await import('./icons/tokenpocket.js')).default,
  'Tokenary Wallet': async () => (await import('./icons/tokenary.js')).default,
  'Trust Wallet': async () => (await import('./icons/trust.js')).default,
  'Wallet.io': async () => (await import('./icons/walletio.js')).default,
  'XDEFI Wallet': async () => (await import('./icons/xdefi.js')).default,
  Zeal: async () => (await import('./icons/zeal.js')).default,
  Zerion: async () => (await import('./icons/zerion.js')).default,
  imToken: async () => (await import('./icons/imtoken.js')).default,
}
