import { mainnet, sepolia, type Chain } from 'viem/chains'
import type { IconName } from 'components/ui'

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL as string
const isDev = process.env.NEXT_PUBLIC_APP_ENV === 'development'

const rpcByChains = {
  [mainnet.id]: 'https://ethereum-rpc.publicnode.com',
  [sepolia.id]: 'https://ethereum-sepolia-rpc.publicnode.com',
} as const

export const tokensBySymbol: Record<string, TokenData> = {
  USDT: {
    address: isDev
      ? '0x72d6a42377E7511Ff69aAb65Fb3C075512fCBae5'
      : '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
    icon: 'tokens/usdt',
    name: 'USDT',
    symbol: 'USDT',
  },
  USDC: {
    address: isDev
      ? '0xa6b92FCD4Ee124353C8A6AcF1Edb574F46F3f8DF'
      : '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
    icon: 'tokens/usdc',
    name: 'USDC',
    symbol: 'USDC',
  },
  DAI: {
    address: isDev
      ? '0x2A3A3872c242c35093a8fc48DaC838C4B2D24a03'
      : '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
    icon: 'tokens/dai',
    name: 'DAI',
    symbol: 'DAI',
  },
  SHIB: {
    address: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
    decimals: 18,
    icon: 'tokens/shib',
    name: 'SHIB',
    symbol: 'SHIB',
  },
  DOGE: {
    // BNB Chain
    address: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    decimals: 8,
    icon: 'tokens/doge',
    name: 'DOGE',
    symbol: 'DOGE',
  },
  PEPE: {
    address: '0x6982508145454Ce325dDbE47a25d4ec3d2311933',
    decimals: 18,
    icon: 'tokens/pepe',
    name: 'PEPE',
    symbol: 'PEPE',
  },
  FLOKI: {
    address: '0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E',
    decimals: 9,
    icon: 'tokens/floki',
    name: 'FLOKI',
    symbol: 'FLOKI',
  },
  POPCAT: {
    address: '0x57274697d3752AC113A53D91Dc284E20C0C71cc1',
    decimals: 18,
    icon: 'tokens/popcat',
    name: 'POPCAT',
    symbol: 'POPCAT',
  },
  WIF: {
    address: '0x8AEC4bbDcfB451aA289bfBD3C2F4E34A44ADa1be',
    decimals: 9,
    icon: 'tokens/wif',
    name: 'WIF',
    symbol: 'WIF',
  },
  BONK: {
    address: '0x4aeF9BD3fBb09d8f374436D9ec25711A1Be9BaCb',
    decimals: 5,
    icon: 'tokens/bonk',
    name: 'BONK',
    symbol: 'BONK',
  },
}

export type ChainId = typeof mainnet.id | typeof sepolia.id

const appChainId = isDev ? sepolia.id : mainnet.id

export type ChainData = Chain & {
  icon: string
  iconName: IconName
  wrappedNative?: { name: string; symbol: string; decimals: number; address: Address }
}

const chainsMap: Record<ChainId, ChainData> = {
  [mainnet.id]: {
    ...mainnet,
    icon: '/images/icons/networks/ethereum.svg',
    iconName: 'networks/eth',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    wrappedNative: {
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    },
    rpcUrls: {
      default: {
        http: [rpcByChains[mainnet.id], ...mainnet.rpcUrls.default.http],
      },
    },
  },
  [sepolia.id]: {
    ...sepolia,
    icon: '/images/icons/networks/ethereum.svg',
    iconName: 'networks/eth',
    nativeCurrency: { name: 'ETH Sepolia', symbol: 'ETH', decimals: 18 },
    wrappedNative: {
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      // https://github.com/SpoolFi/spool-v2-testnet-utils/blob/master/src/deploy/sepolia.constants.json
      address: '0x88B25A14DaE46c33ae3EEF2B45A5ec758D9a5Ec6',
    },
    rpcUrls: {
      default: {
        http: [rpcByChains[sepolia.id], ...sepolia.rpcUrls.default.http],
      },
    },
  },
}

const transition = {
  delayChildren: 0.3,
  staggerChildren: 0.2,
}

const baseAnimationVariants = {
  container: {
    hidden: { opacity: 0.05, y: 10 },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
  },
  itemY: {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition,
    },
  },
}

export default {
  isDev,
  baseUrl,
  rpcByChains,
  appChainId,
  chainsMap,
  baseAnimationVariants,
  tokensBySymbol,
}
