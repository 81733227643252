import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import {
  type GetPublicClientReturnType,
  getPublicClient,
  type GetWalletClientReturnType,
  getWalletClient,
  type Config,
} from '@wagmi/core'
import { type HttpTransport } from 'viem'
import wagmiConfig from '../config'
import type { ChainId } from 'helpers'

export function publicClientToProvider<
  config extends Config,
  chainId extends config['chains'][number]['id'] | number | undefined,
>(publicClient: GetPublicClientReturnType<config, chainId>) {
  const { chain, transport } = publicClient!
  const network = {
    chainId: chain?.id,
    name: chain?.name,
    ensAddress: chain?.contracts?.ensRegistry?.address,
  }

  const url =
    transport.type === 'fallback'
      ? (transport.transports as ReturnType<HttpTransport>[])[0].value?.url || transport.url
      : transport.url

  return new JsonRpcProvider(url, network)
}

/** Action to convert a viem Public Client to an ethers.js Provider. */
export function getEthersProvider({ chainId }: { chainId?: ChainId } = {}) {
  const publicClient = getPublicClient(wagmiConfig, { chainId })

  return publicClientToProvider(publicClient)
}

export function walletClientToSigner(walletClient: GetWalletClientReturnType) {
  const { account, chain, transport } = walletClient

  const network = {
    chainId: chain?.id,
    name: chain?.name,
    ensAddress: chain?.contracts?.ensRegistry?.address,
  }

  const request =
    transport.type === 'fallback'
      ? (transport.transports as ReturnType<HttpTransport>[])[0]
      : transport

  // @ts-ignore
  const provider = new Web3Provider(request, network)

  return provider.getSigner(account.address)
}

/** Action to convert a viem Wallet Client to an ethers.js Signer. */
export async function getEthersSigner({ chainId }: { chainId?: ChainId } = {}) {
  const walletClient = await getWalletClient(wagmiConfig, { chainId })

  if (!walletClient) {
    return
  }

  return walletClientToSigner(walletClient)
}
