'use client'
import { http, createConfig } from 'wagmi'
// import { injected, walletConnect } from 'wagmi/connectors'
import { mainnet, sepolia } from 'viem/chains'
import { constants } from 'helpers'
import iconImage from 'src/app/icon.png'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'

export const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_ID!

if (!projectId) {
  throw new Error('WalletConnect PROJECT_ID not provided')
}
// const injectedConnector = injected({ shimDisconnect: true, unstable_shimAsyncInject: true })
// const walletConnectConnector = walletConnect({
//   projectId,
//   metadata: {
//     name: 'PiggyBanks',
//     description: 'Earn and Play',
//     icons: [`${constants.baseUrl}/${iconImage.src}`],
//     url: constants.baseUrl,
//   },
//   showQrModal: true,
// })

const wagmiConfig = getDefaultConfig({
  appName: 'PiggyBanks',
  appDescription: 'Earn and Play',
  appIcon: `${constants.baseUrl}/${iconImage.src}`,
  appUrl: constants.baseUrl,
  projectId,
  chains: constants.isDev ? [sepolia] : [mainnet],
  transports: {
    [mainnet.id]: http(constants.rpcByChains[mainnet.id]),
    [sepolia.id]: http(constants.rpcByChains[sepolia.id]),
  },
  // connectors: [injectedConnector, walletConnectConnector],
  ssr: false,
  syncConnectedChain: true,
  multiInjectedProviderDiscovery: true,
})

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig
  }
}

export default wagmiConfig
