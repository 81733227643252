import clsx from 'clsx'
import { Dialog, DialogBackdrop, DialogPanel, CloseButton, Transition } from '@headlessui/react'
import { Icon } from 'components/ui'
import { motion } from 'framer-motion'
import confettiImage from 'public/images/confetti.png'

export type PlainModalProps = {
  className?: string
  withCloseButton?: boolean
  withConfetti?: boolean | number
  closeModal: (withOnClose?: boolean) => void
}

const PlainModal: React.CFC<PlainModalProps> = (props) => {
  const { children, className, withCloseButton = true, withConfetti = false, closeModal } = props

  const panelClassName = clsx(
    className,
    'bg-tile-pattern relative flex w-full max-w-lg flex-col rounded-5 bg-repeat p-2 lg:p-4',
    'transition duration-300 ease-out data-[closed]:translate-y-1/2 data-[closed]:scale-90 data-[closed]:opacity-0',
  )

  return (
    <Transition show static appear>
      <Dialog className="relative z-50" open onClose={() => closeModal(true)}>
        <DialogBackdrop
          transition
          className="fixed bottom-0 left-0 size-full bg-accent3-light/60 backdrop-blur-sm transition duration-300 ease-out data-[closed]:opacity-0"
        />
        <div className="fixed bottom-0 left-0 size-full overflow-y-auto overflow-x-hidden overscroll-contain p-4">
          {withConfetti && (
            <motion.img
              key={confettiImage.src}
              src={confettiImage.src}
              className="absolute inset-0 -top-1/2 m-auto w-full lg:-top-1/4 lg:w-[1248px]"
              width={confettiImage.width}
              height={confettiImage.height}
              // initial={{ opacity: 0, y: 120, scale: 0 }}
              animate={{
                opacity: [0, 1, 1, 1, 0],
                y: [90, 0],
                scale: [0, 1],
              }}
              transition={{
                duration: 0.75,
                type: 'keyframes',
                // repeat: 1,
                // repeatType: 'loop',
              }}
            />
          )}
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel
              transition
              className={panelClassName}
              onClick={(event) => event.stopPropagation()}
            >
              <div className="relative min-h-64 flex-1 rounded-5 bg-white px-4 py-6 shadow-y-4-10 lg:px-6">
                {withCloseButton && (
                  <CloseButton className="absolute right-4 top-4 hocus:opacity-70 lg:right-6 lg:top-6">
                    <Icon name="ui/close" className="size-5" />
                  </CloseButton>
                )}
                {children}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PlainModal
