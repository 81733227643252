'use client'
import { type State, WagmiProvider as WagmiBaseProvider } from 'wagmi'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import config from './config'

const WagmiProvider: React.CFC<{ initialState?: State }> = (props) => {
  const { children, initialState } = props

  return (
    <WagmiBaseProvider config={config} initialState={initialState}>
      <RainbowKitProvider>{children}</RainbowKitProvider>
    </WagmiBaseProvider>
  )
}

export default WagmiProvider
