'use client'

import { Message } from '@locmod/intl'
import type { ModalComponent } from '@locmod/modal'

import CommonModal, { type CommonModalProps } from 'compositions/modals/CommonModal/CommonModal'
import messages from './messages'

type SuccessModalProps = {
  title?: Intl.Message | string
  text?: Intl.Message | string
}

const SuccessModal: ModalComponent<SuccessModalProps & Omit<CommonModalProps, 'title'>> = (
  props,
) => {
  const { title, text, ...commonModalProps } = props

  return (
    <CommonModal title={title || messages.title} iconName="ui/status-success" {...commonModalProps}>
      {Boolean(text) && <Message value={text} tag="p" html />}
    </CommonModal>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ SuccessModal: typeof SuccessModal }> {}
}

export default SuccessModal
