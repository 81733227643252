export default {
  balance: {
    en: 'Balance',
  },
  support: {
    en: 'Support',
  },
  disconnect: {
    en: 'Disconnect',
  },
}
