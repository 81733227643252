import(/* webpackMode: "eager" */ "/app/local_modules/wallet/config.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useWallet"] */ "/app/local_modules/wallet/utils/useWallet.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/local_modules/wallet/WagmiProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@rainbow-me/rainbowkit/dist/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"900\"],\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"fontInter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Source_Serif_4\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--font-source-serif\"}],\"variableName\":\"fontSourceSerif\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/navigation/ActiveLink/ActiveLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/navigation/Href/Href.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui/Icon/Icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/compositions/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/compositions/Header/components/ProfileButton/ProfileButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/compositions/modals/GlobalModalsRegistrar/GlobalModalsRegistrar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","useAuthContext"] */ "/app/src/contexts/AuthProvider/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/BaseProviders/BaseProviders.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/scss/globals.scss");
