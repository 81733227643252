export default {
  title: {
    en: 'Error',
  },
  text: {
    en: "Something went wrong.. Let's try again?",
  },
  details: {
    en: 'Details: {errorText}',
  },
}
